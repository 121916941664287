import { useCallback, useEffect, useState } from "react";

export const useHash = <HashString extends string>(
  supportedHashes: readonly HashString[],
): [hash: HashString | "", updateHash: (newHash: HashString) => void] => {
  const [hash, setHash] = useState<HashString | "">(() => {
    const initialHash = supportedHashes.find(
      (supportedHash) => supportedHash === window.location.hash,
    );

    if (initialHash === undefined) {
      window.location.hash = "";
      return "";
    }

    return initialHash;
  });

  const hashChangeHandler = useCallback(() => {
    setHash(
      supportedHashes.find(
        (supportedHash) => supportedHash === window.location.hash,
      ) || "",
    );
  }, [supportedHashes]);

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
    };
  }, [hashChangeHandler]);

  const updateHash = useCallback(
    (newHash: HashString) => {
      if (newHash !== hash) window.location.hash = newHash;
    },
    [hash],
  );

  return [hash, updateHash];
};
